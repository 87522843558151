import logo from './logo.svg';
import './App.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement} from "chart.js";
import { Line } from "react-chartjs-2";
import { useState, useRef } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);
let INITIAL_CHART_DATA = {
  labels: [],
  datasets:[],
}

try{
  INITIAL_CHART_DATA = JSON.parse(localStorage.getItem('chart')) || INITIAL_CHART_DATA 
 }
catch {
  console.error('can not parse chart data')
}




function prepareData(data){
  return {
    labels: data["dates"],
  datasets: [
    {
      id: 1,
      label: 'Деньги на руках',
      data: data["money"],
      borderColor: 'rgb(30, 99, 132)',
      backgroundColor: 'rgba(30, 99, 132, 0.5)',
      radius: 0
    },
    {
      id: 2,
      label: 'Сбережения',
      data: data["savings"],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 240, 132, 0.5)',
      radius: 0
    },
  ],
  }
}

function Button(props) {
  return <span className='button' onClick={event => props.change(props.children)}> {props.children} </span>
}

function Instruction(props) {
  return <div>
<Button change={props.change}>с 2023.05.23 по 2024.05.23</Button>
<Button change={props.change}>инфляция 15%</Button>
<Button change={props.change}>откладывать 0.5% от денег</Button>
<Button change={props.change}>откладывать 1% от прибыли</Button>

<Button change={props.change}>каждый день -400 на еду</Button>
<Button change={props.change}>каждый месяц 10 числа 7000 зарплата 1 без инфляции</Button>
<Button change={props.change}>каждый месяц 25 числа -7000 коммуналка</Button>
<Button change={props.change}>каждую неделю по понедельникам -1500 вождение кат Б</Button>

<Button change={props.change}>2023.07.23 -12000 читалка</Button>

</div>
}
function Editor(props) {
  const initialScenario = localStorage.getItem("scenario")
  
  return <textarea onChange={props.onChange} ref={props.ref_}
    placeholder='Введите сценарий' defaultValue={initialScenario}
    autoFocus/>
}

function Result(props){
  const options = {maintainAspectRatio:false}
  return <Line  
  datasetIdKey='id'
  data={props.data}
  options={options}
/>
}



function App() {
  const [data, setData] = useState(INITIAL_CHART_DATA)
  const ref = useRef()

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer =
        setTimeout(() => func.apply(context, args), delay);
    }
  }   
  
  let initiateNewChart = value => {
    const BACKEND_URI = 'https://nohope.ru/evaluate'
    //const BACKEND_URI = 'http://localhost:5000/evaluate'
    fetch(BACKEND_URI, {
      method: "POST",
      body: JSON.stringify({ 
        text: value
      }),
      headers: {'Content-Type': 'application/json'}
    }
    ).then(response=>response.json()).then(
        function(data) {
          localStorage.setItem("scenario", value)
          const chartData = prepareData(data)
          localStorage.setItem('chart', JSON.stringify(chartData))
          return setData(chartData)
        }
      
      )
  }
  let handleChange = (event) => {
    event.persist();
    initiateNewChart(event.target.value)
      
    }
  let optimisedHandleChange = debounce(handleChange,500); 


  return (
    <div className="App">
      <div><Instruction change={value=>{ref.current.value = ref.current.value + '\n' + value; initiateNewChart(ref.current.value)}}/> </div>
      <div><Editor setData={setData} onChange={optimisedHandleChange} ref_={ref}/></div>
      <div className='Result'> <Result data={data}/> </div>
      <div className='Footer'> пишите мне на eugene.katsevman@gmail.com или в <a href="https://t.me/eugene_mk"> телеграм </a></div>
    </div>
  ); 
}

export default App;
